.modalContent {
  max-height: 97vh;
  overflow: auto; }

.modalOverFlow {
  max-height: 97vh; }

.showModal {
  opacity: 1; }

.hideModal {
  opacity: 0; }

.dispalyHide {
  display: none; }

.popupFlow {
  z-index: 10200 !important; }

.modalCloseIcon {
  width: 13px;
  float: right; }

.retainModal {
  z-index: 10201 !important; }
