input.inputField {
  position: relative;
  height: 52px;
  border-radius: 2px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 23px 12px 6px; }

.customLabel {
  composes: position-absolute fw-normal from '@scss/global.css';
  pointer-events: none;
  -webkit-transition: all .2s ease;
  -o-transition: all ease .2s;
  transition: all .2s ease;
  z-index: 1; }

.labelFocused {
  composes: fs-10 from '@scss/global.css'; }

.labelBlur {
  composes: fs-14 from '@scss/global.css'; }

.inputFocused {
  composes: position-relative from '@scss/global.css'; }
  .inputFocused .customLabel {
    color: #696969;
    top: 5px;
    left: 12px; }

.inputBlur {
  composes: position-relative from '@scss/global.css'; }
  .inputBlur .customLabel {
    top: 15px;
    left: 12px;
    color: #767676; }
