.colorBlue {
  color: #008297; }

.loginOutLook {
  height: auto;
  border: 1px solid #ced4da;
  background-color: #fff;
  border-radius: 0.25rem; }

.colorWhite {
  color: #fff; }

.border {
  border-radius: 0.25rem; }

.backgroundBlue {
  background-color: #008297; }

.linksPadding {
  border-right: 1px solid #4A4A4A !important;
  border: 0px;
  margin-right: 0.3rem !important;
  padding-right: 0.3rem !important; }

.footerStyle {
  background-color: #767676 !important;
  color: #FFFFFF !important;
  font-size: 0.85rem;
  margin: 0px; }

.btnImgUrlSize {
  width: 100%;
  height: 70px;
  object-fit: contain; }

.btnImgUrlText {
  left: 50%;
  transform: translate(-50%, 0);
  line-height: 0.8;
  top: 40%; }
