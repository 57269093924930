.lineStyle {
  padding-left: 1.28571429em;
  text-indent: -1.28571429em; }

.custom-card {
  background-color: #F4F4EF;
  border: none; }

.btnImgUrlSize {
  width: 100%;
  height: 60px;
  object-fit: contain; }

.btnImgUrlText {
  left: 50%;
  transform: translate(-50%, 0);
  line-height: 0.8;
  top: 40%; }
