.btnImgUrlSize {
  width: 100%;
  height: 80px;
  object-fit: contain; }

.btnImgUrlText {
  left: 50%;
  transform: translate(-50%, 0);
  line-height: 0.8;
  top: 40%; }
