.footerList {
  background-color: #f4f4ef;
  width: 100%; }

.footerFixed {
  height: 60px;
  position: fixed;
  bottom: 0;
  width: 100%; }

.footerPopUp {
  bottom: 60px;
  position: absolute;
  min-width: 150px;
  background: #4A4A4A;
  left: 0;
  height: 70vh;
  overflow-y: scroll; }

.modelColor {
  color: inherit !important; }

.selectedLanguage {
  background-color: #767676 !important; }

.languageListHeight {
  max-height: 350px;
  overflow-y: auto; }

@media screen and (max-width: 320px) {
  .footerPopUp {
    overflow-y: auto;
    height: 420px; } }

@media screen and (min-width: 1200px) {
  .margin5 {
    margin-right: 5%; } }

@media screen and (min-width: 1024px) {
  .top0 {
    top: 0px; }
  .size35 {
    border-radius: 50%;
    width: 35px;
    height: 35px; }
  .padding18 {
    height: 82px;
    padding: 0px 18px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
  .logoWidth {
    max-width: 195px; }
  .footerFixed {
    height: 0px;
    top: 20px;
    z-index: 9; }
  .desktopFooterItems {
    flex-direction: row; }
  .desktopFooterItems nav {
    height: auto;
    box-shadow: none; }
  .desktopFooterItems nav img {
    height: 60px; }
  .footerPopUp {
    left: 100px;
    bottom: -170px;
    height: auto;
    overflow-y: scroll;
    min-width: 280px; }
  ::-webkit-scrollbar {
    width: 12px; }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px; }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); } }

.homeIcon {
  mask: url("@assets/images/footerIcons/home.svg") no-repeat center/contain;
  background-color: #000;
  height: 20px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat; }

.bookIcon {
  mask: url("@assets/images/footerIcons/book.svg") no-repeat center/contain;
  background-color: #000;
  height: 20px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat; }

.utensilIcon {
  mask: url("@assets/images/footerIcons/utensil.svg") no-repeat center/contain;
  background-color: #000;
  height: 20px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat; }

.bagIcon {
  mask: url("@assets/images/footerIcons/bag.svg") no-repeat center/contain;
  background-color: #000;
  height: 20px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 5px; }

.ellipsisIcon {
  mask: url("@assets/images/footerIcons/ellipsis.svg") no-repeat center/contain;
  background-color: #000;
  height: 20px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat; }

.markerIcon {
  mask: url("@assets/images/footerIcons/marker.svg") no-repeat center/contain;
  background-color: #000;
  height: 20px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat; }

.userClockIcon {
  mask: url("@assets/images/footerIcons/userClock.svg") no-repeat center/contain;
  background-color: #000;
  height: 20px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat; }

.userIcon {
  mask: url("@assets/images/footerIcons/user.svg") no-repeat center/contain;
  background-color: #000;
  height: 20px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat; }

.thumbStackIcon {
  mask: url("@assets/images/footerIcons/thumbStack.svg") no-repeat center/contain;
  background-color: #000;
  height: 20px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat; }

.storeIcon {
  mask: url("@assets/images/footerIcons/store.svg") no-repeat center/contain;
  background-color: #000;
  height: 20px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat; }

.chevronLeftIcon {
  mask: url("@assets/images/footerIcons/chevronLeft.svg") no-repeat center/contain;
  background-color: #000;
  height: 20px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat; }

.chevronRightIcon {
  mask: url("@assets/images/footerIcons/chevronRight.svg") no-repeat center/contain;
  background-color: #000;
  height: 20px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat; }

.chequeIcon {
  mask: url("@assets/images/footerIcons/cheque.svg") no-repeat center/contain;
  background-color: #000;
  height: 20px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat; }

.heartIcon {
  mask: url("@assets/images/footerIcons/heart.svg") no-repeat center/contain;
  background-color: #000;
  height: 20px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat; }

.syncIcon {
  mask: url("@assets/images/footerIcons/sync.svg") no-repeat center/contain;
  background-color: #000;
  height: 20px;
  width: 30px;
  background-position: center;
  background-repeat: no-repeat; }

.bg-primary {
  background-color: #DA1B41; }

.storeImg {
  object-fit: fill;
  width: 48px;
  height: 24px; }

.badgeIconMarginTop {
  margin-top: -32px !important;
  margin-left: 4px !important; }

.searchCloseCalendar {
  position: absolute;
  right: 25%;
  top: 10px;
  bottom: 0;
  margin: auto;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center; }

.searchBoxCalendar {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  height: 36px;
  border: 1px solid #ddd;
  width: 100%;
  border-radius: 5px; }

.selectedLanguage {
  background-color: #767676 !important; }

@media screen and (min-width: 1024px) {
  input:focus, textarea:focus, select:focus {
    outline: none; }
  .searchBoxCalendar::placeholder {
    font-style: italic;
    font-size: 13px;
    border: 0px solid #ddd; }
  .searchBoxCalendar {
    min-width: 392px;
    border: 0px solid #ddd; }
  .searchCloseCalendar {
    top: 0px !important;
    right: 28%; }
  .logoWidth {
    max-width: 195px;
    object-fit: contain; } }
