.selectedStore {
  box-shadow: inset 0 0 0 3px #AD0028, 0 2px 5px rgba(0, 0, 0, .5) !important;
}

.selectedStore img, .normalStore img {
  width: 100%;
  max-height: 50px;
  object-fit: cover;
}

.menuCard {
  // height: calc(100vh - 125px);
  width: 100%;
  background-color: #FFFFFF;
  overflow: auto;
  z-index: 999;
}

.menuItem {
  background-color: #f4f4ef;
  // min-height: 50px;
}

@media screen and (min-width: 640px) {
  .menuCard {
    width: 40vw;
    position: absolute;
    left: calc(50% - 20vw);
  }
}

@media screen and (min-width: 1024px) {
  .menuCard {
    width: 40vw;
    position: unset !important;
    left: calc(50% - 20vw);
  }
}
