.loginColumns {
  margin-top: 10px; }

.license {
  margin-left: 20px;
  font-size: 0.8em; }

.textRight {
  text-align: end; }

.scrollHeight {
  height: 50vh;
  overflow-y: scroll; }

.react_phonenr_input {
  width: 60px !important;
  height: 63px !important;
  font-size: 1rem !important;
  position: relative !important; }

.phonenr_input {
  font-size: 80%; }

.phoneTextField {
  padding-left: 8px; }

@media (max-width: 320px) {
  .react_phonenr_input {
    width: 40px !important; } }

.overFlowNone {
  overflow: unset !important; }

.whiteSpace {
  white-space: normal; }
