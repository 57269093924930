.footerBtm {
  z-index: 9999;
  position: fixed;
  bottom: 13px;
  width: 100%; }

@media screen and (min-width: 1024px) {
  .desktopFooter {
    background: none !important;
    bottom: 0px !important;
    width: 63px !important; } }
