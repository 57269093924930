.nav {
  z-index: 10080;
  box-shadow: none !important; }

.headerTitle {
  font-size: 0.8rem; }

.restaurant {
  top: 60px;
  height: 30px;
  line-height: 15px;
  flex-wrap: unset !important; }

.sideNav {
  height: 100vh;
  width: 250px;
  position: absolute;
  background-color: #f8f9fa;
  overflow: auto;
  display: flex;
  flex-direction: column; }

.right8 {
  right: 8px !important; }

.menuScroll {
  align-content: flex-start;
  justify-content: center !important;
  overflow: auto;
  flex-grow: 1; }

.menuMask {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.3);
  border: 0;
  z-index: 10000;
  position: fixed; }

.modalCloseIcon {
  width: 13px;
  float: right; }

.subMenuDiv {
  padding: 12px;
  font-size: .9em; }

.subMenuDiv:hover {
  background-color: #F4F4EF; }

.subMenuDivActive {
  background-color: #91917F;
  color: white; }

.subMenuFooter {
  flex: 1 0 auto;
  max-height: 70px;
  min-height: 70px;
  flex-shrink: 0;
  align-items: flex-end;
  justify-content: center !important;
  border-top: 1px solid #91917F; }

.menuProfile {
  flex: 1 0 auto;
  max-height: 180px;
  min-height: 180px;
  flex-shrink: 0; }

.menuImg {
  width: 90px;
  height: 90px;
  border-radius: 50%; }

.pencilMenuImg {
  position: relative;
  right: 0;
  top: -1.6em; }

.subMenuImg {
  width: 20px;
  height: 20px;
  margin-right: 15px;
  vertical-align: sub; }

.menuIcon {
  width: 20px;
  height: 14px; }

.loginBtn {
  height: 25px;
  line-height: 5px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  font-size: 16px;
  border: 0;
  border-radius: 5px; }

.logoutLink {
  color: black;
  right: 0; }

.menuLinks {
  background-color: #fff; }

.menuLinks ul li {
  list-style: none;
  float: left; }

.cartQuantity {
  line-height: 1.2em;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  color: white; }

.menuwrapper {
  overflow-x: scroll;
  overflow-y: hidden; }

.menuwrapper span {
  padding: 3px 7px; }

.menuwrapper div {
  overflow: hidden;
  background-color: #E0E0DB;
  color: #91917F; }

.menuwrapper > div:first-child {
  border-top-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em; }

.menuwrapper > div:last-child {
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em; }

.menuSelected {
  background-color: #91917F !important;
  color: white !important; }

.imgSize {
  width: 50px;
  height: auto; }

@media only screen and (max-width: 640px) {
  .menuScroll {
    height: 220px;
    overflow: auto;
    width: 100%; } }

@media only screen and (min-width: 1024px) {
  .imgSize {
    width: 50px;
    height: 120px; } }

.border {
  border-radius: 8px;
  border: 2px solid grey;
  background-color: grey; }

.borderBottom {
  border-bottom: 2px solid #000; }

.menuName {
  display: inline-block;
  text-align: center;
  white-space: nowrap; }

.accountMenu {
  padding-left: 35px; }

.logoSize {
  height: 25px; }

.headerModel {
  position: fixed;
  min-width: 150px;
  top: 40px;
  background: white;
  right: 0;
  background: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  padding: 5px; }

.menuHr {
  margin-left: 5px;
  margin-right: 5px;
  border: 0.5px solid;
  margin-top: 10px;
  margin-bottom: 10px; }

.checkInInfo {
  text-align: left; }

.spaceEvenly {
  justify-content: space-evenly; }

.contentEnd {
  justify-content: end; }

.selectedLanguage {
  background-color: #767676 !important; }

.languageListHeight {
  max-height: 350px;
  overflow-y: auto; }

.customIconClass {
  left: calc(50vw - 36.5em) !important;
  top: 92.5vh !important; }

@media screen and (max-width: 1199px) {
  .customIconClass {
    top: 92.5vh !important;
    left: calc(50vw - 30.5em) !important; } }

@media screen and (min-width: 1200px) and (max-width: 1262px) {
  .customIconClass {
    top: 92.5vh !important;
    left: calc(50vw - 34.8em) !important; } }

@media screen and (max-width: 1023px) {
  [data-url*="order-item"] .customIconClass {
    top: 0.5em !important;
    left: 3em !important; }
  .customIconClass {
    min-width: 35px !important;
    min-height: 35px !important;
    left: 5em !important;
    top: 0px !important;
    width: 35px !important;
    height: 35px !important; }
  .userWayImageIcon {
    max-width: 35px !important;
    max-height: 35px !important;
    width: 35px !important;
    height: 35px !important; } }

@media screen and (min-width: 1999px) {
  .customIconClass {
    top: 92.5vh !important;
    left: 3em !important; } }
