.react_phonenr_input {
  width: 60px !important;
  height: 63px !important;
  font-size: 1rem !important;
  position: relative !important; }

.phonenr_input {
  font-size: 80%; }

.phoneTextField {
  padding-left: 8px; }

@media (max-width: 320px) {
  .react_phonenr_input {
    width: 40px !important; } }

@media (min-width: 1024px) {
  .react_phonenr_input {
    width: 40px !important;
    height: 64px !important;
    font-size: 1rem !important;
    position: relative !important; }
  .phonenr_input {
    font-size: 80%;
    padding-right: 0px !important; } }

.inputField {
  height: 50px; }

.btnImgUrlSize {
  width: 100%;
  height: 80px;
  object-fit: contain; }

.btnImgUrlText {
  left: 50%;
  transform: translate(-50%, 0);
  line-height: 0.8;
  top: 40%; }
