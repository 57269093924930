.width {
  width: 90vw;
  height: 80vh; }

.signUpOutLook {
  height: auto;
  border: 1px solid #ced4da;
  background-color: #fff;
  border-radius: 0.25rem; }

.colorWhite {
  color: #fff; }

.border {
  border-radius: 0.25rem; }

.backgroundBlue {
  background-color: #008297; }

.colorBlue {
  color: #008297; }

.linksPadding {
  border: 0px;
  margin-right: 0.3rem !important;
  padding-right: 0.3rem !important;
  border-right: 1px solid #4A4A4A !important; }

.footerStyle {
  background-color: #767676 !important;
  color: #FFFFFF !important;
  font-size: 0.85rem;
  margin: 0px; }
