.notificationBar {
  position: fixed;
  z-index: 100000;
  width: 100%;
  border-radius: 0;
  padding: 15px;
  transition: 500ms;
  margin-top: -250px;
  text-align: center;
  transition-property: all;
  color: rgba(0, 0, 0, 0.5);
  width: calc(100vw - 20px);
  left: 10px;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2); }

.notificationOpen {
  margin-top: 0; }

.notificationClose {
  margin-top: -250px; }

.alertSuccess {
  background-color: #83CE12; }

.alertDanger {
  background-color: #ED5565; }
