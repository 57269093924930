.marginTop3 {
  margin-top: 3px;
  margin-left: -18px;
}

.termsContainer a {
  text-decoration: underline;
}

.btnImgUrlSize {
  width: 100%;
  height: 60px;
}
.btnImgUrlText {
  left: 50%;
  transform: translate(-50%, 0);
  line-height: 0.8;
  top: 40%;
}

.ProgressBar {
  width: 100%;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.Progress {
  background: #ad0028;
  height: 100%;
  border-color: #4A90E2;
  border-radius: 5px;
}

@keyframes progressBar {
  0% {
    width: 10%;
  }
  
  100% {
    width: 50%;
  }
}