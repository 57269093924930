.phonenr_input{
  font-size: 80%;
  padding-left: 30px !important;
}

.react_phonenr_input{
  width: 60px !important;
  height: 50px !important;
  font-size: 1rem !important;
  position: relative !important;
}
.btnImgUrlSize {
  width: 100%;
  height: 80px;
  object-fit: contain;
}
.btnImgUrlText {
  left: 50%;
  transform: translate(-50%, 0);
  line-height: 0.8;
  top: 40%;
}
@media (max-width: 320px){
  .react_phonenr_input{
    width: 60px !important;
  }
}
