.mallwrapper{
  text-align: center;
  max-height: 80px;
  display: flex;
  overflow-x: scroll;
  z-index: 999;;
}

.menuCard {
  min-height: calc(100vh - 130px);
  width: 100%;
  background-color: #FFFFFF;
  overflow-y: hidden;
  z-index: 999;
}

.menuContainer {
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin: 0;
}

.menuItem {
  background-color: #f4f4ef;
  min-height: 50px;
}

.menuItem img {
  width: 100%;
  max-height: 50px;
  object-fit: cover;
}

.selectedMenu {
  box-shadow: inset 0 0 0 3px #AD0028, 0 2px 5px rgba(0, 0, 0, .5) !important;
}

.removeMenu {
  display: none;
}
.menuHr {
  margin: 0;
  margin-left: 5px;
  margin-right: 5px;
  border: 0.5px solid;
}